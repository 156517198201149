import { ComponentFactoryResolver, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './component/navbar/navbar.component';
import { FooterComponent } from './component/footer/footer.component';
import { DialogComponent } from './component/dialog/dialog.component';
import { DialogService } from './component/dialog/dialog.service';
import { AlertComponent } from './dialog/alert/alert.component';



@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    DialogComponent,
    AlertComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    DialogComponent,
    AlertComponent
  ]
})
export class GlobalModule {
  static forRoot(): ModuleWithProviders<GlobalModule> {
    return {
      ngModule: GlobalModule,
      providers: [DialogService]
    }
  }
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }
  public resolveComponent(component: any) {
    return this.componentFactoryResolver.resolveComponentFactory(component);
  }
 }
