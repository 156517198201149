
<header>
    <div class="container justify-content-center navbar-main d-none d-md-flex">
        <a routerLink="/" class="d-flex align-items-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none logo-link">
            <img src="assets/images/auth/gb-logo.png" alt="mdo">
        </a>
    </div>
    <div class="d-block d-md-none bg-white p-3 navbar-main">
        <div class="d-flex img-1 px-1">
            <a routerLink="/" class="d-flex align-items-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none cursor-pointer logo-link">
                <img src="assets/images/auth/gb-logo.png" alt="mdo" height="48" class="d-none d-md-block">
                <img src="assets/images/auth/gb-logo-small.png" class="logo-small d-md-none" alt="" >
            </a>
        </div>
    </div>
</header>