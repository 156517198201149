import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AlertService } from 'src/app/global/service/alert.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  serverUrl!: string | undefined;

  constructor(
    private http: HttpClient,
    private alertService: AlertService
  ) { }

  supportRequest(userData: any, region: any) {
    this.updateServerUrl(region);
    return this.http.post(`${this.serverUrl}/support-requests`, userData);
  }

  /**
 * Updates the serverUrl based on the region.
 *
 * @param region - The region to determine the serverUrl.
 */
  updateServerUrl(region: string) {
    if (environment.useRegionSpecificUrls) {
      // Use region-specific server URL
      switch (region) {
        case 'us':
          this.serverUrl = environment.usServerUrl;
          break;
        case 'eu':
          this.serverUrl = environment.euServerUrl;
          break;
        default:
          this.serverUrl = undefined;
          this.alertService.error('Invalid region specified', 400); // Show error message
          break;
      }
    } else {
      // Use default server URL for development/qa/staging environments
      this.serverUrl = environment.defaultServerUrl;
    }
  }

}
